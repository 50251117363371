.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login-img-ecora {
  margin: 0 auto 10px;
}

/* ---------- SIGN IN ---------- */
.sign-in__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.sign-in__backdrop {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.sign-in__wrapper form {
  width: 24rem;
  max-width: 90%;
  z-index: 1;
  animation: showSignInForm 1s;
}

.sign-in__wrapper form img {
  width: 4rem;
}

@keyframes showSignInForm {
  0%,
  30% {
    transform: translate(0, -150%);
  }
  70%,
  90% {
    transform: translate(0, 1rem);
  }
  80%,
  100% {
    transform: translate(0, 0);
  }
}

.Embed-container {
  width: 100%;
  height: 69vh;
  margin: 1% auto;
}

.fondo_general {
  background-color: azure;
  height: 100%;
}
